import React, { useState } from 'react';
import { graphql } from 'gatsby';
import HtmlHead from '../../components/html-head';
import Navbar from '../../components/navbar';
import './event.css';
import Footer from '../../components/footer';
import { PageData } from '../../types';
import tsai_img from '../../assets/event/tsia.png'
import calander_img from '../../assets/event/calander_icon.png'
import pin_img from '../../assets/event/pin_icon.png'
import message_icon from '../../assets/event/message_icon.png'
import las_vegas from '../../assets/event/Frame 1000011074.png'
import group_img from '../../assets/event/Group 1000011071.png'
import sofa_icon from '../../assets/event/sofa_icon.png'
import { sendEventToGa } from '../../utils';
import validateBusinessEmail from '../../utils/validate-business-email';


interface event {
    formName: string;
    mainTitle: string;
    title: string;
    image: string;
    formId: string;
}


const Event: React.FC<PageData> = (resp, event) => {
    const seoData = {
        title: " Meet us at TSIA World Envision 2024 | AptEdge",
        canonicalLink: "https://www.aptedge.io/tsia-world-envision-2024/",
        metaDescription: {
            metaDescription: "Join AptEdge at TSIA World Envision 2024 in Vegas, October 21-23. Learn to navigate B2B customer support, solve tickets faster, and boost efficiency!",
        },
        openGraphImage: {
            file: {
                url: "",
            },
        },
    };

    return (
        <>
            <HtmlHead
                title={seoData?.title}
                metaDescription={seoData?.metaDescription?.metaDescription}
                canonicalLink={seoData?.canonicalLink}
                openGraphImage={seoData?.openGraphImage?.file?.url}
            />
            <Navbar />
            <div className='event'>
                <div className='container'>
                    <div className='event-form-section'>
                        <div className='event-form-section-left'>
                            <img src={tsai_img} alt="TSIA" />
                            <h1>Meet us at</h1>
                            <p className='second-heading'>TSIA World Envision 2024</p>
                            <p className='details'>Learn how to navigate the B2B support maze. Break free from customer confusion and support inefficiency.
                                Solve B2B tickets faster with AptEdge.</p>
                            <div className='location'>
                                <div><img src={calander_img} alt="Calendar" /> <p>October 21-23, 2024</p></div>
                                <div><img src={pin_img} alt="Location" /> <p>Aria Resort & Casino, Vegas</p></div>
                            </div>
                        </div>
                        <div id="event-form" className='event-form-section-right'>
                            <form action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00D4P000000hMNv" method="POST">
                                <input type="hidden" name="oid" value="00D4P000000hMNv" />
                                <input type="hidden" name="retURL" value="https://aptedge.io/thank-you" />
                                <input type="hidden" id="lead_source" name="lead_source" value="Web" />
                                <input type="hidden" id="00NVW000000H4lZ" name="00NVW000000H4lZ" value="TSIA Conference Form" />
                                <label htmlFor="first_name">First Name*</label>
                                <input id="first_name" maxLength={40} name="first_name" size={20} type="text" required />
                                <br />
                                <label htmlFor="last_name">Last Name*</label>
                                <input id="last_name" maxLength={80} name="last_name" size={20} type="text" required />
                                <br />
                                <label htmlFor="email">Business Email*</label>
                                <input id="email" maxLength={80} name="email" size={20} type="email" required />
                                <br />
                                <label htmlFor="city">City</label>
                                <input id="city" maxLength={40} name="city" size={20} type="text" />
                                <br />
                                <button type="submit" name="submit" className="app-btn">Schedule Now</button>
                                <p className='tsia-privacy-policy'>I understand and agree that the information submitted in this form will be transmitted to, stored and processed by AptEdge, in accordance with their <a href='/website-privacy-policy/'>privacy policy</a>.</p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className='event-two'>
                <div className="container">
                    <div className='event-section-two'>
                        <div className="event-section-2-left">
                            <img src={message_icon} alt="message_icon" />
                            <div className='event-section-2-left-content'>
                                <h1>Vegas is all about taking chances, but meeting with us at TSIA World Envision 2024 is a guaranteed win.</h1>
                                <p>Let’s connect and make sure your time at the conference is worth every minute!</p>
                                <p>Mark your calendar for one of the top technology industry conferences, dedicated to driving smarter, more efficient organizations. Featuring game-changing keynotes, breakout sessions, actionable insights, networking opportunities, and more,
                                    it’s a can’t-miss event for B2B leaders focused on transforming Customer Support.</p>
                            </div>
                        </div>
                        <div className="event-section-2-right">
                            <img src={las_vegas} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="event-section-3">
                <div className='container'>
                    <div className='event-section-two reverse'>
                        <div className="event-section-2-left img-reverse">
                            <img src={sofa_icon} alt="message_icon" />
                            <div className='event-section-2-left-content'>
                                <h1>Join us The Edge Suite – Your VIP Escape at the Aria!</h1>
                                <p>Need a break from the conference buzz? Step up to The Edge Suite at the Aria, exclusively hosted by AptEdge. Whether you’re recharging, catching up with your fellow B2B leaders, or just relaxing with a drink and some great food, this is your go-to spot to unwind.</p>
                                <p>No agenda, no rush—just a perfect mix of comfort and conversation. Reflect on what you've learned or simply take a breather—whatever you need, The Edge Suite is your VIP lounge. Drinks are on us, whenever you're ready!</p>
                            </div>
                            <button className='event-section-2-left-button'>Pre-order your drinks</button>
                        </div>
                        <div className="event-section-2-right">
                            <img src={group_img} alt="" />
                        </div>
                    </div>
                </div>
                <div className="video">
                    <iframe width="916" 
                    height="416" 
                    src="https://www.youtube.com/embed/_mhO_0zEJrs?si=P8k-2xWYCmNYRUdI" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerpolicy="strict-origin-when-cross-origin" 
                    allowfullscreen>
                    </iframe>
                </div>
            </div> */}
            <div className="event-section-3">
                <div className='container'>
                    <div className='event-section-two reverse'>
                        <div className="event-section-2-left img-reverse">
                            <img src={sofa_icon} alt="sofa_icon" />
                            <div className='event-section-2-left-content'>
                                <h1>Join us The Edge Suite – Your VIP Escape at the Aria!</h1>
                                <p>Need a break from the conference buzz? Step up to The Edge Suite at the Aria, exclusively hosted by AptEdge. Whether you’re recharging, catching up with your fellow B2B leaders, or just relaxing with a drink and some great food, this is your go-to spot to unwind.</p>
                                <p>No agenda, no rush—just a perfect mix of comfort and conversation. Reflect on what you've learned or simply take a breather—whatever you need, The Edge Suite is your VIP lounge. Drinks are on us, whenever you're ready!</p>
                            </div>
                            <a href="#event-form"><button className='event-section-2-left-button'>Pre-order your drinks</button></a>
                        </div>
                        <div className="event-section-2-right img-section-2">
                            <img src={group_img} alt="group_img" />
                        </div>
                    </div>
                </div>
                <div className="video">
                    <iframe width="916"
                        height="416"
                        src="https://www.youtube.com/embed/_mhO_0zEJrs?si=P8k-2xWYCmNYRUdI"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>

            <div className="event-section-4">
                <div className='container'>
                    <div className='event-section-4-wrapper'>
                        <div className='event-section-4-content'>
                            <h1>Ready to <span>Connect & Collaborate?</span></h1>
                            <p>Book your VIP meeting at The Edge Suite during TSIA World Envision 2024—where meaningful conversations, networking, and a bit of Vegas fun are waiting for you!</p>
                            <a href="https://calendly.com/aptedge-sales/30min" target='_blank'>
                                <button>Book a Meeting</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Event;

export const query = graphql`
    query EventQuery {
        allContentfulPageSeoData(filter: { pagePath: { eq: "/event/event" } }) {
            edges {
                node {
                    title
                    canonicalLink
                    metaDescription {
                        metaDescription
                    }
                    openGraphImage {
                        file {
                            url
                        }
                    }
                }
            }
        }
    }
`;

function setformSuccess(arg0: boolean) {
    throw new Error('Function not implemented.');
}
